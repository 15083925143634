<template>
  <v-card
    flat
    outlined
    class="stat_card"
    v-if="value !==null && !skeleton"
    :loading="loading"
  >
    <v-card-text>
      <div class="number">
        {{ value }}
      </div>
      <div>
        <b>{{ name }}</b>
      </div>
    </v-card-text>
  </v-card>

  <v-skeleton-loader
    v-else
    type="card"
    height="72"
    class="mb-2"
  />
</template>

<script>
export default {
  name: "StatsCard",
  props: {
    name: { type: String, default: null },
    value: { type: [String, Number], default: null },
    skeleton: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
}
</script>

<style scoped>
  .stat_card {
    /*width: fit-content;*/
    text-align: left;
  }

  .number {
    color: #b5b5b5;
    font-weight: bold;
    font-size: 2.2em;
  }
</style>
